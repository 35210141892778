<template>
  <div class="ckbox-render">
    <v-progress-circular
      v-if="isCh"
      color="primary"
      indeterminate
      mr-3
      size="24"
      width="3"
    />
    <v-checkbox
      v-if="!isCh"
      v-model="checkBoxValue"
      :disabled="checkBoxDisabled"
      :hide-details="true"
      :ripple="false"
      @click="onClick($event)"
    />
  </div>
</template>

<script>
import staffApi from "api/staff";
import { mapGetters } from "vuex";
import { MSA_CLIENT_ERROR, UI_ACTIONS } from "@/utils/constants";

export default {
  name: "CheckBoxCellRender",
  data() {
    return {
      isCh: false
    };
  },
  computed: {
    ...mapGetters({
      staff: "staffByLocation",
      extStaff: "extStaffByLocation",
      locationId: "selectedSchoolId",
      fnActionByName: "getActionByName",
      selectedAppId: "selectedAppId"
    }),
    locationName() {
      const location = this.$store.getters.locations.find(
        l => l.id === this.locationId
      );
      return location ? location.name : "";
    },
    locationType() {
        const location = this.$store.getters.locations.find(
        l => l.id === this.locationId
        );
        return location ? location.locType : "";
    },
    colId() {
      return this.params.column.colId;
    },
    taskInfo() {
      let taskInfo = {};
      taskInfo.hasAccess = this.params.value;
      taskInfo[`${this.colId}Birthright`] = this.params.data[
        `${this.colId}Birthright`
      ];
      taskInfo[`${this.colId}TaskId`] = this.params.data[`${this.colId}TaskId`];
      taskInfo[`${this.colId}TaskName`] = this.params.data[
        `${this.colId}TaskName`
      ];
      return taskInfo;
    },
    checkBoxValue: {
      get() {
        return this.params.value ?? false;
      },
      set(newValue) {
        return newValue;
      }
    },
    checkBoxDisabled() {
      return (
        !this.fnActionByName(UI_ACTIONS.PROVISION_APPLICATION) &&
        !this.fnActionByName(UI_ACTIONS.DEPROVISION_APPLICATION)
      );
    }
  },
  methods: {
    handleChange() {
      this.params.setValue(!this.params.value);
    },

    async onClick(e) {
      e.preventDefault();
      const self = this;
      if (self.checkBoxDisabled) return;
      const rowData = self.params.data;
      if (!rowData.netRegId) {
        return this.$store.commit("showError", {
          show: true,
          message: MSA_CLIENT_ERROR.shortNameMissing
        });
      }
      self.isCh = true;
      const params = {
        locationId: parseInt(this.locationId),
        locType: this.locationType,
        locationName: this.locationName,
        detUserId: rowData.detUserId,
        staffId: rowData.netRegId,
        applicationId: self.selectedAppId,
        taskId: self.taskInfo[`${this.colId}TaskId`],
        isGrantPermission: !self.taskInfo.hasAccess,
        enabledTasks: "",
        revokeAll: false
      };
      const {
        locationId,
        locType,
        locationName,
        detUserId,
        staffId,
        applicationId,
        taskId,
        isGrantPermission,
        enabledTasks,
        revokeAll
      } = params;
      try {
        //console.log("Before calling provisionAppAccess from onClick: locType-"+this.locationType);
        await staffApi.provisionAppAccess(
          locationId,
          locType,
          locationName,
          detUserId,
          staffId,
          applicationId,
          taskId,
          isGrantPermission,
          enabledTasks,
          revokeAll
        );
        if (rowData.isAdditional) {
          this.$store.commit("setReloadStaffListRequired");
        }
        this.$store.commit("setReloadApplicationsRequired", true);
        self.handleChange();
        self.params.changed();
      } catch (e) {
        throw new Error("An error occurred: " + e.message);
      }
      self.isCh = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.ckbox-render {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  margin: 0 0.5rem;
}
</style>
