<script>
import { AgGridVue } from "@ag-grid-community/vue";
import { AG_ALPINE_THEME, PAGE_FORMATS } from "@/utils/constants";

export default {
  name: "GridTable",
  props: {
    rowData: {
      type: Array,
      required: true
    },
    modules: {
      type: Array,
      default: () => []
    },
    pageFormat: {
      type: String,
      default: PAGE_FORMATS.multi
    },
    pageSize: {
      type: Number,
      default: 10
    },
    columnDefs: {
      type: Array,
      required: true
    },
    pinHeader: {
      type: Boolean,
      default: true
    }
  },
  components: { AgGridVue },
  data() {
    return {
      defaultColDef: {
        editable: false,
        sortable: true,
        filter: true,
        flex: 1,
        resizable: true,
        autoHeight: true,
        minWidth: 165,
        wrapText: false,
        wrapHeaderText: true,
        autoHeaderHeight: true
      },
      tblKey: 1,
      gridApi: null,
      columnApi: null,
      agTheme: AG_ALPINE_THEME,
      multiPagesType: PAGE_FORMATS.multi
    };
  },
  watch: {
    pageFormat: function(format) {
      if (format === PAGE_FORMATS.multi) {
        this.tblKey += 1;
      }
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    onKeyDownHandler(params) {
      const { colId } = params.column;
      if (
        params.colDef.cellRenderer === "checkBoxCellRender" &&
        params.event.code === "Enter"
      ) {
        const rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
        const ps = { columns: [colId], rowNodes: [rowNode] };
        const instances = this.gridApi.getCellRendererInstances(ps);
        if (instances.length > 0) {
          const instance = instances[0];
          instance.onClick(params.event);
        }
      }
    }
  }
};
</script>

<template>
  <ag-grid-vue
    v-if="rowData"
    :key="tblKey"
    :class="[
      $vuetify.theme.dark ? agTheme.dark : agTheme.light,
      pinHeader ? 'pin-header' : ''
    ]"
    :column-defs="columnDefs"
    :default-col-def="defaultColDef"
    :modules="modules"
    :pagination="pageFormat === multiPagesType"
    :pagination-page-size="pageSize"
    :paginationPageSizeSelector="[10, 20, 40]"
    :row-data="rowData"
    class="pb-3 pr-3 pl-3"
    dom-layout="autoHeight"
    style="width: 100%; height: 100%;"
    tooltip-show-delay="500"
    @cellKeyDown="onKeyDownHandler($event)"
    @grid-ready="onGridReady"
  />
  <v-row
    v-else
    align="center"
    class="btn-row no-staff pl-3 pt-3 pb-3 pr-3"
    justify="center"
  >
    <span>No staff found</span>
  </v-row>
</template>
