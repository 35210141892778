<template>
  <div>
    <v-container
      :class="{
        mobile: $vuetify.breakpoint.smAndDown,
        page: $vuetify.breakpoint.smAndDown
      }"
    >
      <v-btn
        :ripple="false"
        aria-label="Back to applications"
        class="desktop-back-button no-shadow font-weight-regular ml-4 ml-sm-0 pl-0"
        text
        @click="toggleView()"
      >
        <v-icon>mdi-arrow-left</v-icon>
        <span class="ml-2">Back to applications</span>
      </v-btn>
      <div v-if="!isLoading">
        <v-card class="msa-list mt-3">
          <section class="d-flex flex-column">
            <v-row
              align="center"
              class="btn-row pl-3 pt-3 pb-3 pr-3"
              justify="space-between"
            >
              <label id="select-app-label" class="visuallyhidden">
                Your Applications
              </label>
              <v-autocomplete
                v-model="currentAppId"
                :items="schoolApps"
                :menu-props="{ contentClass: 'app-picker-menu' }"
                aria-labelledby="select-app-label"
                class="select-app"
                hide-details
                item-text="applicationDesc"
                item-value="applicationId"
                placeholder="Select Application..."
                solo
                v-bind="{ flat: $vuetify.breakpoint.mdAndDown }"
              />
              <v-btn
                v-if="
                  $vuetify.breakpoint.mdAndUp &&
                    fnActionByName(uIActions.LIST_GUEST_STAFF) 
                "
                aria-label="Add Staff"
                class="primary"
                @click="openStaffFilter"
              >
                <v-icon class="mr-3">
                  mdi-account-plus-outline
                </v-icon>
                Add Staff
              </v-btn>
            </v-row>
            <hr />
            <grid-table-controls :is-gov="isGovSchool" />
            <grid-table
              :column-defs="columnDefs"
              :modules="modules"
              :page-format="pageFormat"
              :page-size="pageSize"
              :row-data="rowData"
            />
          </section>
        </v-card>
      </div>
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        bottom
        class="primary"
        fab
        fixed
        right
        @click="openStaffFilter"
      >
        <v-icon>mdi-account-plus-outline</v-icon>
      </v-btn>

      <v-dialog
        v-if="showStaffFilter"
        v-model="showStaffFilter"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        max-width="675px"
      >
        <StaffFilter
          :location-id="locationId"
          @close="showStaffFilter = false"
          @search="search"
        />
      </v-dialog>
      <v-dialog
        v-if="showStaffSearchResults"
        v-model="showStaffSearchResults"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        content-class="staff--search-results-modal"
        max-width="750px"
        persistent
      >
        <StaffSearchResults
          :filter-params="staffFilterParams"
          @close="showStaffSearchResults = false"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import CheckBoxCellRender from "@/components/CheckBoxCellRender.vue";
import { mapGetters } from "vuex";
import StaffFilter from "@/views/StaffFilter.vue";
import StaffSearchResults from "@/components/StaffSearchResults.vue";
import {
  FAT_APPLICATIONS,
  STAFF_TYPES,
  UI_ACTIONS,
  VUE_APP_AG_GRID_ENTERPRISE
} from "@/utils/constants";
import GridTable from "@/components/GridTable.vue";
import { getConfig } from "@/utils/config";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import GridTableControls from "./GridTableControls.vue";

export default {
  name: "ProvisionMatrix",
  components: {
    GridTable,
    StaffFilter,
    StaffSearchResults,
    GridTableControls,
    // eslint-disable-next-line vue/no-unused-components
    checkBoxCellRender: CheckBoxCellRender
  },
  data() {
    return {
      showStaffFilter: false,
      showStaffSearchResults: false,
      staffFilterParams: {},
      externalStaff: STAFF_TYPES.external,
      columnDefs: null,
      uIActions: UI_ACTIONS
    };
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      isLoading: "isLoading",
      reloadStaffListRequired: "reloadStaffListRequired",
      fnActionByName: "getActionByName",
      schoolApps: "schoolApps",
      staffAccess: "staffAccessOfAppAtLocation",
      selectedAppId: "selectedAppId",
      isGov: "isGov",
      appTasks: "selectedAppTaskList",
      staffType: "staffType",
      pageFormat: "pageFormat",
      pageSize: "pageSize"
    }),
    currentAppId: {
      get() {
        return this.selectedAppId;
      },
      set(newId) {
        this.$store.commit("setSelectedAppId", newId);
        return newId;
      }
    },
    staffAccessList() {
      return this.staffAccess(this.selectedAppId);
    },
    rowData() {
      const isAdditional = this.staffType === this.externalStaff;
      return (
        this.staffAccessList?.filter(
          staff => staff.isAdditional === isAdditional
        ) ?? []
      );
    },
    taskNames() {
      return this.appTasks()?.map(t => t.taskName);
    },
    isGovSchool() {
      return this.isGov(this.locationId);
    },
    locationType() {
        const location = this.$store.getters.locations.find(
        l => l.id === this.locationId
        );
        return location ? location.locType : "";
    },
    isFatApplication() {
      return FAT_APPLICATIONS.has(this.selectedAppId);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    modules() {
      const useAgGridEnterprise =
        getConfig(VUE_APP_AG_GRID_ENTERPRISE) === "true";
      return useAgGridEnterprise
        ? [
            ClientSideRowModelModule,
            ColumnsToolPanelModule,
            ExcelExportModule,
            MenuModule,
            SetFilterModule
          ]
        : [ClientSideRowModelModule];
    }
  },
  watch: {
    selectedAppId: {
      immediate: true,
      handler(newAppId) {
        if (newAppId !== "") {

          //console.log("Before calling the fetchStaffAccessOfAppAtLocation from ProvMatrix selectedAppId: locType-"+this.locationType);
          this.$store.dispatch("fetchStaffAccessOfAppAtLocation", {
            locationId: this.locationId,
            locType: this.locationType,
            appId: this.selectedAppId
          });
        }
      }
    },
    staffAccessList: {
      immediate: true,
      handler() {
        const spaceTight = this.isMobile || this.isFatApplication;
        const baseColDef = [
          {
            field: "firstName",
            headerName: "Staff",
            pinned: "left",
            sort: "asc",
            sortIndex: 0,
            headerTooltip: "First name last name. Sortable by the first name",
            cellRenderer: params => {
              return `<b>${params.value} ${params.data.lastName}</b>`;
            }
          },
          { field: "lastName", hide: true },
          { field: "detUserId", hide: spaceTight || this.isGovSchool },
          { field: "netRegId", hide: true },
          { field: "email", hide: spaceTight || !this.isGovSchool },
          { field: "role", hide: true }
        ];
        const taskColDefs = this.getTaskColDefs();
        this.columnDefs = [...baseColDef, ...taskColDefs];
      }
    }
  },
  methods: {
    openStaffFilter() {
      this.showStaffFilter = true;
    },
    search(params) {
      this.staffFilterParams = params;
      this.showStaffSearchResults = true;
    },
    toggleView() {
      this.$emit("toggle-matrix");
    },
    getTaskColDefs() {
      return (
        this.taskNames.map(t => {
          return {
            field: t,
            editable: false,
            tooltipValueGetter: () =>  this.getTaskDescription(t),
            cellRenderer: "checkBoxCellRender",
            cellRendererParams: {
              isChecking: false,
              changed() {}
            }
          };
        }) ?? []
      );
    },
    getTaskDescription(taskNme) {
        const task = this.appTasks().find(
                t => t.taskName === taskNme
                );
        return task ? task.taskDesc : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.msa-list {
  section {
    display: block;

    .btn-row {
      margin: 0;

      &.no-staff {
        min-height: 9rem;
        border-top: 1px solid $ads-light-20;
      }

      .select-app {
        max-width: 30rem;
      }
    }
  }
}

::v-deep .v-dialog.staff--search-results-modal {
  min-height: 50vh;
}

::v-deep .v-dialog--active {
  background-color: #ffffff;
}
</style>
