<template>
  <div>
    <v-container
      :class="{
        mobile: $vuetify.breakpoint.smAndDown,
        page: $vuetify.breakpoint.smAndDown
      }"
    >
      <v-card class="app-view-component">
        <v-card-title>Applications applicable:</v-card-title>
        <v-card-text class="apps-container">
          <button
            v-for="app in schoolApps"
            :key="app.applicationId"
            :class="{ 'app-selected': selectedAppId === app.applicationId }"
            class="app-tile"
            @click="toggleView(app.applicationId)"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span>
                  <button class="tooltip-btn" v-on="on">
                    <AppIcon :app-name="app.icon" />
                  </button>
                </span>
              </template>
              <span>{{ app.applicationDesc }}</span>
            </v-tooltip>
            <v-card-subtitle>
              {{ app.applicationName.substring(0, 5) }}
            </v-card-subtitle>
          </button>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AppIcon } from "@nswdoe/doe-ui-core";

export default {
  name: "ProvisionApp",
  components: {
    AppIcon
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      locType: "selectedLocType",
      isLoading: "isLoading",
      schoolApps: "schoolApps",
      selectedAppId: "selectedAppId"
    })
  },
  watch: {
    locationId: function(newSchool, oldSchool) {
      if (newSchool !== null && newSchool !== oldSchool) {
        this.$store.commit("setSelectedAppId", "");
        this.$store.commit("setSelectedLocType", "");
      }
    },
    selectedAppId: function(newAppId) {
      if (newAppId !== "") {
        //console.log("Before calling the fetchStaffAccessOfAppAtLocation from ProvApp selectedAppId: locType-"+this.locType);
        return this.$store.dispatch("fetchStaffAccessOfAppAtLocation", {
          locationId: this.locationId,
          locType: this.locType,
          appId: this.selectedAppId
        });
      }
      this.$store.commit("setStaffAccess", {
        appId: newAppId,
        staffAccess: null
      });
    }
  },
  methods: {
    toggleView(id) {
      this.$store.commit("setSelectedAppId", id);
      this.$emit("toggle-matrix");
    }
  }
};
</script>

<style lang="scss" scoped>
.apps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  row-gap: 1.5rem;
  padding: 1rem;

  .v-card__subtitle {
    padding: 0;
  }

  .app-tile {
    border-radius: 0.25rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 5px 0px rgba(0, 0, 0, 0.12);

    &:focus {
      outline: 1px solid $ads-dark-60;
    }
  }

  .tooltip-btn:focus {
    height: 40px;
    width: 40px;
    border-radius: 0.25rem;

    &:focus {
      outline: 1px solid $ads-light-40;
    }
  }
}

.v-card__title {
  border-bottom: 1px solid $ads-light-20;
}
</style>
